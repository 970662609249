import HTTP from "../http-common";

/**
 * Получение пляжей на карту
 *
 * @module getSightsByType
 */
export default (type) =>
  HTTP.get("geojson/locations/getSightsByType", {
    params: { type },
    headers: {
      "api-version": "null",
    },
    cache: false,
  });
