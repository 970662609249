import HTTP from "../http-common";

/**
 * Получение j, j,]trnt
 *
 * @module getObject
 * @return {object} Promise
 */
export default (objectId) =>
  HTTP.get("json/objects/getObjectLocation", {
    params: { objectId },
    headers: {
      "api-version": 1.8, // Если указать версию, то будет минимум данных
    },
    cache: false,
  });
