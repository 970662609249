import HTTP from "../http-common";

/**
 * Получение списка избранных
 *
 * @module getFavoritesList
 * @return {object} Promise
 */
export default () =>
  HTTP.get("json/objects/getFavoritesList", {
    headers: {
      "api-version": null,
    },
    cache: false,
  });