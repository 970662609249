import * as Sentry from "@sentry/vue";

async function lazyLoadSentryIntegrations() {
    Sentry.addIntegration(Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }));
}

function getSentryIntegrations() {
    const router = useRouter();

    const browserTracing = new Sentry.browserTracingIntegration({ router });
    const captureConsole = Sentry.captureConsoleIntegration({levels: ["error"]});

    return [browserTracing, captureConsole];
}



export default defineNuxtPlugin({
    name: 'sentry',
    parallel: true,
    async setup(nuxtApp) {
      const vueApp = nuxtApp.vueApp;

      const { 
        public: { 
          SENTRY_RELEASE_ID, 
          SENTRY_DSN_PUBLIC, 
          SENTRY_ENVIRONMENT,
          SENTRY_SAMPLE_RATE,
          SENTRY_TRACES_SAMPLE_RATE, 
          SENTRY_REPLAY_SAMPLE_RATE, 
          SENTRY_ERROR_REPLAY_SAMPLE_RATE  
        } 
      } = useRuntimeConfig();

      if (!SENTRY_DSN_PUBLIC) {
        return
      }

      Sentry.init({
        release: SENTRY_RELEASE_ID, 
        app: vueApp,
        dsn: SENTRY_DSN_PUBLIC,
        environment: SENTRY_ENVIRONMENT,
        attachStacktrace: true, // for captureConsoleIntegration
        integrations: getSentryIntegrations(),
        ignoreErrors: [
          "Hydration completed but contains mismatches",
        ],

        sampleRate: SENTRY_SAMPLE_RATE,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

        // This sets the sample rate. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: SENTRY_REPLAY_SAMPLE_RATE,

        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: SENTRY_ERROR_REPLAY_SAMPLE_RATE,
      });

      // Lazy-load the replay integration to reduce bundle size
      lazyLoadSentryIntegrations();
    }
});