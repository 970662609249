<template>
  <div class="main__notfound">
    <Header>
      <template #suggest-desktop>
        <BaseSuggestionsSearchHeader />
      </template>
    </Header>
    <BaseNotFound />

    <Footer />
  </div>
</template>

<script>
import { Header } from "@sutochno/header";
import { Footer } from "@sutochno/footer";
import BaseNotFound from "@/components/base/BaseNotFound.vue";
import BaseSuggestionsSearchHeader from "@/components/base/BaseSuggestions/SearchHeader";

export default {
  components: {
    Header,
    BaseSuggestionsSearchHeader,
    Footer,
    BaseNotFound,
  },
};
</script>

<style lang="scss" scoped>
.main__notfound {
  display: flex;
  flex-direction: column;
  /* align-items: stretch; */
  justify-content: space-between;
  min-height: 100vh;
  background-color: #f1f3fb;
}
</style>
