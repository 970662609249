<i18n lang="json">
{
	"ru": {
		"mo": "пн",
    "tu": "вт",
    "we": "ср",
    "th": "чт",
    "fr": "пт",
    "sa": "сб",
    "su": "вс"
	},
  "en": {
		"mo": "mo",
    "tu": "tu",
    "we": "we",
    "th": "th",
    "fr": "fr",
    "sa": "sa",
    "su": "su"
	}
}
</i18n>

<template>
  <div
    class="sc-datepickerext-calendar"
    :class="{
      'sc-datepickerext-horizontal': orientation == 'horizontal',
      'sc-datepickerext-vertical': orientation == 'vertical',
    }"
  >
    <div
      class="sc-datepickerext-days_week"
      v-once
      v-if="orientation == 'vertical'"
    >
      <div class="days_week-text">
        <span
          v-for="(day, i) in daysWeek"
          :key="day"
          class="days_week-item tmp-font--small"
          :class="{
            red: i == 5 || i == 6,
          }"
          >{{ t(day) }}</span
        >
      </div>
      <div class="hr" />
    </div>
    <div class="sc-datepickerext-calendar_body" v-if="viewContent">
      <!-- v-observe-visibility="{
          callback: (isVisible, entry) => visibilityChanged(isVisible, entry, `month_${i}`),
          throttle: 0,
          intersection: {
            threshold: 0,
          }
        }" -->
      <div
        v-for="(date, i) in dateMonth"
        :key="date.toLocaleString()"
        class="month-wrapper"
      >
        <Month
          :ref="`month_${i}`"
          :date-month="date"
          :start-disable="startDisable"
          :end-disable="endDisable"
          :employment="employment"
          :cntMin="cntMin"
          :choiceEployment="choiceEployment"
          :days="dateMonthDays[i]"
          :orientation="orientation"
        />
      </div>
    </div>
    <div v-show="!viewContent" class="sc-datepickerext-calendar_body">
      <keep-alive>
        <div class="loading" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
import Month from "./Month.vue";
import range from "lodash/range";
import chunk from "lodash/chunk";
import flatten from "lodash/flatten";
// Vue.directive("observe-visibility", ObserveVisibility);
import { useI18n } from 'vue-i18n';

export default {
  name: "Calendar",
  components: {
    Month,
  },
  props: {
    modelValue: {
      type: [Date, Object],
    },
    type: {
      default: "range",
      type: String,
      validator(value) {
        return ["single", "range"].includes(value);
      },
    },
    beginDate: {
      type: Date,
      require: true,
    },
    countMonth: {
      default: 2,
      type: Number,
    },
    orientation: {
      default: "horizontal",
      type: String,
      validator(value) {
        return ["vertical", "horizontal"].includes(value);
      },
    },
    changeRange: {
      default: null,
      validator(value) {
        return [null, "in", "out"].includes(value);
      },
    },
    startDisable: {
      type: Date,
      default: () => new Date(),
    },
    endDisable: {
      type: Date,
      default: () => {
        let year = new Date();
        return new Date(year.setFullYear(year.getFullYear() + 1));
      },
    },
    employment: {
      type: Array,
      default: () => [],
    },
    cntMin: {
      type: Number,
      default: null,
    },
    fillMode: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      date: {
        in: null,
        out: null,
        single: null,
        error: null,
      },
      hoverDate: null,
      daysWeek: ["mo", "tu", "we", "th", "fr", "sa", "su"],
      choiceEployment: null,
      errorText: null,
      viewContent: true,
    };
  },
  computed: {
    daysCount() {
      const days =
        this.date && this.date.in && this.date.out
          ? Math.floor(
              (this.date.out.getTime() - this.date.in.getTime()) /
                (1000 * 60 * 60 * 24)
            )
          : 0;
      return days;
    },
    dateMonth() {
      return range(0, this.countMonth).map(
        (month) =>
          new Date(
            this.beginDate.getFullYear(),
            this.beginDate.getMonth() + month,
            1
          )
      );
    },
    dateMonthDays() {
      if (!this.dateMonth) return null;

      return this.dateMonth.map((el) => {
        let days = [];
        if (el.getDay() > 0) {
          days = Array(el.getDay() - 1); //Воссоздаем пропущеные дни недели на нaчало месяца (-1 день недели для сдвига дней недель)
        } else {
          days = Array(6);
        }
        let lastDay = new Date(el.getFullYear(), el.getMonth() + 1, 0);
        days = days.concat(range(1, lastDay.getDate() + 1)); // Заполняем днями месяца
        if (lastDay.getDay() != 0)
          days = days.concat(Array(7 - (days.length % 7))); //Воссоздаем пропущеные дни недели на конец месяца (+1 день недели для сдвига дней недель)

        return chunk(days, 7); // Разбиваем по неделям
      });
    },
    startDateMonth() {
      return new Date(
        this.beginDate.getFullYear(),
        this.beginDate.getMonth(),
        1
      );
    },
    dateIn: {
      get() {
        return this.date.in;
      },
      set(value) {
        // Меняем местами даты если они выбраны в обратном порядке
        if (
          value &&
          this.date.out &&
          value.getTime() > this.date.out.getTime()
        ) {
          if (this.$parent.$parent.changeRange == "in") {
            this.date.out = null;
          } else {
            let tmp = this.date.out;
            this.date.out = value;
            value = tmp;
          }
        }
        this.date.in = value;
        // this.$emit("update:modelValue", this.date);
        this.$parent.$parent.$emit("update:dateViews", this.date);
        this.$emit("update:dateViews", this.date);
        if (this.dateIn && this.dateOut) {
          this.$emit("update:modelValue", this.date);
        }
      },
    },
    dateOut: {
      get() {
        return this.date.out;
      },
      set(value) {
        // Меняем местами даты если они выбраны в обратном порядке
        if (value && this.date.in && value.getTime() < this.date.in.getTime()) {
          if (this.$parent.$parent.changeRange == "out") {
            this.date.in = null;
          } else {
            let tmp = this.date.in;
            this.date.in = value;
            value = tmp;
          }
        }
        this.date.out = value;
        this.$parent.$parent.$emit("update:dateViews", this.date);
        this.$emit("update:dateViews", this.date);
        if (this.dateIn && this.dateOut) {
          this.$emit("update:modelValue", this.date);
        }
      },
    },
    dateSingle: {
      get() {
        return this.date.single;
      },
      set(value) {
        this.date.single = value;
        this.$emit("update:modelValue", this.date.single);
      },
    },
  },
  watch: {
    cntMin(val) {
      if (val > this.daysCount) {
        this.date.error = this.dateOut;
        this.dateOut = null;
        this.errorText = "cntMin";
      }
    },
    date: {
      deep: true,
      handler() {
        if (
          (this.date.in && !this.date.out) ||
          (this.date.in && this.date.error)
        )
          this.closeDatesByChoice();
        else this.choiceEployment = null;

        this.$emit("update:dateViews", this.date);
      },
    },
    daysCount(val) {
      // this.$parent.$parent.$emit('update:daysCount', val);
      this.$emit("update:daysCount", val);
    },
    modelValue: {
      deep: true,
      immediate: true,
      handler() {
        if (this.modelValue instanceof Date) {
          this.date.single = this.modelValue;
        } else if (this.modelValue instanceof Object) {
          if (this.type !== "range") {
            this.date.in = this.modelValue.in;
            this.date.out = this.modelValue.out;
          }
        }
      },
    },
  },
  mounted() {
    // Подстановка даты
    if (this.modelValue instanceof Date) {
      this.date.single = this.modelValue;
    } else if (this.modelValue instanceof Object) {
      this.date.in = this.modelValue.in;
      this.date.out = this.modelValue.out;
    }
    // Если в датах есть ошибка мы всеровно ее подставляем но с ошибкой
    if (this.cntMin > this.daysCount) {
      this.date.error = this.date.out;
      this.date.out = null;
      this.errorText = "cntMin";
    }

    setTimeout(() => {
      this.viewContent = true;
    }, 0);
  },
  methods: {
    visibilityChanged(isVisible, entry, ref) {
      if (this.$refs && this.$refs[ref] && this.$refs[ref][0]) {
        this.$refs[ref][0].view = isVisible ? true : false;
      }
    },
    // initDates() {
    //   if (this.modelValue instanceof Date) {
    //     this.date.single = this.modelValue;
    //   } else if (this.modelValue instanceof Object) {
    //     this.date.in = this.modelValue.in;
    //     this.date.out = this.modelValue.out;
    //   }
    // },
    closeDatesByChoice() {
      const paris = this.employment.map((value) => [
        value.from.getTime(),
        value.to.getTime(),
      ]);
      if (!paris.length) return [];

      const calcTime = this.dateIn ? this.dateIn.getTime() : null;

      let employment = null;

      const fill = this.dateMonthDays
        .filter((v) => v)
        .map((month, i) => {
          const startTime = this.dateMonth[i].getTime();
          return flatten(month).map((day) => {
            if (!day) return;

            const leftPart = startTime + (day - 1) * 86400000;
            const rightPart = startTime + day * 86400000 - 1;
            const isLeftPartEmploy = paris.find(
              (v) => v[0] <= leftPart && leftPart < v[1]
            );

            const isRightPartEmploy = paris.find(
              (v) => v[0] <= rightPart && rightPart < v[1]
            );
            if (
              isLeftPartEmploy &&
              calcTime &&
              leftPart > calcTime &&
              !employment
            ) {
              employment = new Date(leftPart);
              this.choiceEployment = new Date(leftPart);
            }
          });
        });
      fill.map((el) => el.unshift(undefined));
      // Так как дни идут с 1, не с 0
      return fill;
    },
    reset() {
      this.date.in = null;
      this.date.out = null;
      this.date.single = null;
      this.date.error = null;
      this.$parent.$parent.$emit("update:dateViews", this.date);
    },
  },
};
</script>

<style lang="scss" scoped>
.sc-datepickerext-calendar {
  user-select: none;
  line-height: 1;
  color: #000;
  &.sc-datepickerext-vertical {
    display: flex;
    justify-content: space-between;
    align-items: flex;
    flex-direction: column;

    overflow: auto;
    height: calc(100vh - 230px);

    .sc-datepickerext-month {
      margin: 15px 0;
    }
  }
  &.sc-datepickerext-horizontal {
    display: flex;
    width: 100%;

    .sc-datepickerext-days_week {
      position: static;
    }

    .sc-datepickerext-calendar_body {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: row;
    }
    .sc-datepickerext-month {
      min-height: 283px;
    }
    .sc-datepickerext-month:not(:last-child) {
      margin-right: 20px;
    }
  }

  .sc-datepickerext-days_week {
    width: 100%;
    position: sticky;
    top: 0;
    // top: 93px;
    background: #fff;
    z-index: 20;

    .days_week {
      &-text {
        display: flex;
        justify-content: center;
      }
      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 54px;
        height: 45px;
        font-weight: 500;
        color: #252525;
        &.red {
          color: #f51449;
        }
      }
    }
    .hr {
      height: 1px;
      width: 100%;
      background-color: #d8d8d8;
    }
  }
  .sc-datepickerext-calendar_body {
    position: relative;
    flex-grow: 1;
    width: 100%;
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.loading {
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background-color: #f6f6f6;
    z-index: 1;
  }
  &:after {
    width: 25px;
    height: 25px;
    border: 3px solid #d8d8d8;
    border-top-color: #f51449;
  }
  &:after {
    content: "";
    border-radius: 50%;
    -webkit-animation: spin 1s infinite linear;
    animation: spin 1s infinite linear;
    position: absolute;
    left: 50%;
    margin-left: -12.5px;
    top: 50%;
    margin-top: -12.5px;
    z-index: 2;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  &_full {
    &:before {
      opacity: 1;
    }
  }
  &_primary {
    &:before {
      background: #fff;
    }
  }
}
.month-wrapper{
  padding: 30px 0;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &::before{
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    right: -1px;
    top: 0;
    background: #E7E9F3;
  }
}
.month-wrapper + .month-wrapper{
  &::before{
    left: 0;
  }
}
</style>
