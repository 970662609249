<i18n>
{
  "ru": {
    "title": "Поиск жилья"
  },
  "en": {
    "title": "Housing Search"
  }
}
</i18n>

<template>
  <transition>
    <PageModal
      v-if="showSearchModal"
      v-show="!showOnlyOptions"
      @back="store.commit('search/setShowSearchModal', false)"
    >
      <template #header>
        <div class="title">
          {{ t("title") }}
        </div>
      </template>
      <template #default>
        <SearchWidgetMain
          :locationTerm="locationTerm"
          :dates="dates"
          :guests="guests"
          v-model:locationLocalValue="locationLocalValue"
          v-model:datesLocalValue="datesLocalValue"
          v-model:guestsLocalValue="guestsLocalValue"
          @setShowOnlyOptions="showOnlyOptions = $event"
          @setShowOptionType="showOptionType = $event"
          @search="search"
          @resetOptions="resetOptions"
        />
        
      </template>
    </PageModal>
  </transition>
  <transition>
    <Options
      :class="{ 'animation-off': !showOnlyOptions }"
      v-model:location="locationLocalValue"
      v-model:dates="datesLocalValue"
      v-model:guests="guestsLocalValue"
      v-model:showOptionType="showOptionType"
      :locationTerm="locationTerm"
      :showOnlyOptions="showOnlyOptions"
      @setTypeLocation="typeLocation = $event"
      @search="search"
      @resetOptions="resetOptions"
    />
  </transition>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";

import PageModal from "./PageModal";
import SearchWidgetMain from "./SearchWidgetMain";
import Options from "./Options.vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const store = useStore();
const router = useRouter();
const route = useRoute();

const props = defineProps({
  modelValue: Boolean,
  locationTerm: String,
  dates: {
    type: Object,
    default: function () {
      return {
        in: null,
        out: null,
      };
    },
  },
  guests: {
    type: Object,
    default: function () {
      return {
        adults: 2,
        childrens: [],
        pets: {
          value: false,
          description: "",
        },
      };
    },
  },
});
const emits = defineEmits(["update:modelValue", "getObjects"]);

const locationLocalValue = ref(null);
const datesLocalValue = ref(null);
const guestsLocalValue = ref(props.guests);
const showOptionType = ref("");
const typeLocation = ref("");
const locationTerm = ref(props.locationTerm);

const switchMap = computed(() => store.state.search.switchMap);
const showSearchModal = computed(() => store.state.search.showSearchModal);
watch(
  () => props.locationTerm,
  (value) => {
    locationTerm.value = value;
  }
);

watch(
  () => showSearchModal.value,
  (val) => {
    document.body.style.overflow = val ? "hidden" : "";
    store.commit("user/setMenuHiddenState", val || switchMap.value);
    // main page
    if (val && showOnlyOptions.value) {
      showOptionType.value = "location";
    }
  }
);
watch(showOptionType, (value) => {
  // main page
  if (!value && isMainPage.value) {
    store.commit("search/setShowSearchModal", false);
  }
});

// main page
const isMainPage = computed(() => route.name === "Main");
const isValidSearchParams = computed(
  () => props.locationTerm && props.dates.in && props.dates.out
);
const showOnlyOptions = computed(
  () => isMainPage.value && !isValidSearchParams.value
);

onMounted(() => {
  if (route.query["modal-calendar"]) {
    store.commit("search/setShowSearchModal", true);
    showOptionType.value = "dates-in";
  }
  if (route.query["modal-guests"]) {
    store.commit("search/setShowSearchModal", true);
    showOptionType.value = "guests";
  }
});

// задает данные(date, title, guests) при выборе локации
function checkDate(date) {
  if (date.in && date.out) {
    const currentDate = new Date();
    const dateIn = new Date(date.in);
    const dateOut = new Date(date.out);
    currentDate.setHours(0);
    currentDate.setMinutes(0);
    currentDate.setSeconds(0);
    currentDate.setMilliseconds(0);

    if (currentDate > dateIn || currentDate > dateOut) {
      return false;
    }
    return true;
  }
  return false;
}
watch(locationLocalValue, (variant) => {
  if (variant?.guests) {
    guestsLocalValue.value = variant.guests;
  }

  if (variant?.date?.in && variant?.date?.out) {
    if (checkDate(variant.date)) {
      datesLocalValue.value = {
        in: new Date(variant.date.in),
        out: new Date(variant.date.out),
      };
    }
  }
});

// запуск поиска
const isSearchDisabled = computed(
  () =>
    (!locationLocalValue.value && !locationTerm.value && !isMainPage.value) ||
    !datesLocalValue.value.in ||
    !datesLocalValue.value.out
);
function search() {
  if (isSearchDisabled.value) {
    return false;
  }
  const variant = JSON.parse(JSON.stringify(locationLocalValue.value));

  if (variant) {
    const renameKey = (obj, oldKey, newKey) => {
      if (oldKey !== newKey && obj.hasOwnProperty(oldKey)) {
        Object.defineProperty(
          obj,
          newKey,
          Object.getOwnPropertyDescriptor(obj, oldKey)
        );
        delete obj[oldKey];
      }
    };
    if (variant.data && variant.data.type === "hotel") {
      renameKey(variant.bbox.NE, "lon", "lng");
      renameKey(variant.bbox.SW, "lon", "lng");
    }

    store.commit("search/setTerm", variant.title || "");
    store.commit("search/setGuests", guestsLocalValue.value);
    store.dispatch("search/setCalendarDates", {
      checkIn: datesLocalValue.value.in,
      checkOut: datesLocalValue.value.out,
    });

    store.commit("search/setCondidateForHistory", variant);
    store.commit("search/setAnaliticParamForSearchWidList", {
      type: typeLocation.value,
      value: variant.title,
    });

    if (variant.data && variant.data.type === "object") {
      const routeData = router.resolve({
        name: "Detail",
        params: { id: variant.data.id },
      });
      window.open(routeData.href, "_blank");
      return;
    }
    if (variant.data && variant.data.type === "hotel") {
      const idsPayload = {
        key: "ids",
        value: variant.data.ids,
        item: [],
      };
      store.dispatch("filter/updateFilters", idsPayload);
      const hotelPayload = {
        key: "hotel",
        value: variant.title,
        item: {
          key: "hotel",
          value: variant.title,
          text: variant.title,
        },
      };
      store.dispatch("filter/updateFilters", hotelPayload);
    }
  } else {
    store.commit("search/setTerm", locationTerm.value || "");
    store.commit("search/setGuests", guestsLocalValue.value);
    store.dispatch("search/setCalendarDates", {
      checkIn: datesLocalValue.value.in,
      checkOut: datesLocalValue.value.out,
    });
  }

  store.commit("search/updateSwitchMap", false);

  // используется в main
  if (typeLocation.value === "history") {
    store.commit("search/setListType", typeLocation.value);
  } else {
    store.commit("search/setListType", "");
  }

  store.dispatch("search/saveVariantInStorage");
  var { bbox, title, point } = variant || {};

  store.commit("search/setLoadingLocation", true);
  if (variant) {
    store
      .dispatch("search/setLocation", {
        id: variant.location?.id || variant.id,
        type: variant.location?.type || variant.type,
        bbox,
        term: title,
        point,
      })
      .then(() => {
        emits("getObjects");
      });
  } else {
    emits("getObjects");
  }
}

function resetOptions() {
  locationLocalValue.value = null;
  locationTerm.value = "";
  datesLocalValue.value = {
    in: null,
    out: null,
  };
  guestsLocalValue.value = {
    adults: 2,
    childrens: [],
    pets: {
      value: false,
      description: "",
    },
  };
}
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  justify-content: center;
  width: 100%;
}

.search-widget {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  justify-content: space-between;
  height: 100%;

  &__main {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  &__btns {
    display: flex;
    gap: 12px;
  }
  &__search {
    display: flex;
    align-items: center;
    gap: 10px;
    &::before {
      content: url("~/assets/img/icon/search.svg");
      transform: translateY(2px);
    }
  }
}

.field {
  padding: 0 16px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #e7e9f3;
  border-radius: 10px;

  &__title {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #5a5d63;
  }
  &__value {
    color: #1c1c1c;
    font-weight: 500;
    line-height: 20px;

    span {
      font-weight: 400;
      color: #5a5d63;
    }
  }
}
.field-dates {
  flex-direction: row;
  justify-content: space-between;

  &__item {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:first-child {
      padding-right: 5px;
    }
    &:last-child {
      padding-left: 16px;
      border-left: 1px solid #e7e9f3;
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: transform 0.5s ease;
}
.animation-off {
  transition: transform 0s ease !important;
}

.v-enter-from,
.v-leave-to {
  transform: translateX(100%);
}
</style>
