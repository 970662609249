import gaEventBooking from "./gaEventBooking";
import gaEventDraftSa from "./gaEventDraftSa";
import ymSendForm from "./ymSendForm";
import gaSendProduct from "./gaSendProduct";
import gaBottomButton from "./gaBottomButton";
import gaRightButton from "./gaRightButton";
import gtmStep2 from "./gtmStep2";

/**
 * Работа с черновиком
 *
 * Desktop path: @/components/desktop/Booking/
 * Mobile path: @/components/mobile/Booking/
 *
 * @module Detail
 */
export default {
  gaEventBooking,
  gaEventDraftSa,
  ymSendForm,
  gaSendProduct,
  gaBottomButton,
  gaRightButton,
  gtmStep2,
};
