import HTTP from "../http-common";

/**
 * @description редактирования данных и местоположения объекта
 *
 * @module Objects/getProperties
 *
 * @param {number | null} idObj - ID-объекта для которого нужно получить характеристики
 *
 * @param {	string | null} type -	Типа объекта  apartment, house, room, bed  (квартира, дом, комната, койко-место)
 *
 * @param {object} headers - заголовки
 *
 * @param {boolean} isRestruct - нужна обработка или нет
 *
 * @return {object} Promise
 *
 */

export default (idObj, type = null, headers = {}, isRestruct = false) =>
  HTTP.get(`json/objects/getProperties?id=${idObj}&type=${type}`, {
    cache: false,
    headers: {
      "api-version": String(null), // Если указать версию, то будет минимум данных
      ...headers,
    },
  }).then((res) => {
    if (!isRestruct) return res;
    let all = {};
    const props = res.data.data.groups.reduce((acc, o) => {
      const byGroup = res.data.data.properties
        .filter((v) => v.group_id == o.id)
        .reduce((acc, o) => {
          let value, values;
          switch (o.type_name) {
            case "int":
              value = parseInt(o.value);
              break;
            case "check":
              value = o.value == "true";
              break;
            case "text":
              value = o.value;
              break;
            case "bigtext":
              value = o.value;
              break;
            case "list":
              value = o.value;
              values = o.values;
              break;
            default:
              value = o.value;
              break;
          }
          if (values) {
            acc[o.name] = {
              title: o.title,
              value: o.value === null || o.value === "null" ? null : value,
              values,
            };
          } else {
            acc[o.name] = {
              title: o.title,
              value: o.value === null || o.value === "null" ? null : value,
            };
          }
          return acc;
        }, {});
      acc[o.name] = {
        title: o.title,
        value: byGroup,
      };
      all = { ...all, ...byGroup };
      return acc;
    }, {});
    return { all, props };
  });
