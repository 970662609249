// /orders/getOrderDraft?draftCode=EK8RY5kwV&token=mJVruGMgV39AkYqSfFML8Q178283272
import HTTP from "../http-common";
import { formatWithTime } from "@/utils/format";

/**
 * Метод подключает к объекту указанное число приоритетов, отключает услугу или изменяет ее.
 *
 * @module getOffersList
 * @param {object} payload Данные для метода
 * @return {object} Promise
 */

export default (payload) => {

  let params = []

  payload.objectIds.map((val, i) => {
    params[`objects[${i}]`] = val
  })

  // params = {
  //   objec
  // }

  return HTTP.get("/json/services/getDelayedPrByObjects", {
    params: {
      ...params
      // 'objects[0]': payload.objectId
    },
    headers: {
      "api-version": 1.9, // Если указать версию, то будет минимум данных
      "Content-Type": "application/json",
    },
    cache: false
  });
};
