import { createGtm } from "@gtm-support/vue-gtm";

const isServer = typeof window === "undefined";
export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.use(
    createGtm({
      id: "GTM-KTKFS5C",
      enabled: !isServer, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      vueRouter: useRouter(), // Pass the router instance to automatically sync with router (optional)
    })
  )
})