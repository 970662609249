import HTTP from "../http-common";

/**
 * Получение j, j,]trnt
 *
 * @module getPricesAndAvailabilities
 * @param {object} payload Передаваемый объект
 * @param {string} payload.guests Сколько гостей
 * @param {array} payload.ages массив возврастов детей
 * @param {string} payload.time_begin Время заезда
 * @param {string} payload.time_end Время отъезда
 * @param {string} payload.date_begin Дата заезда в формате Y-m-d hh:mm:ss
 * @param {string} payload.date_end Дата отъезда в формате Y-m-d hh:mm:ss
 * @param {array} payload.objects id объекта
 * @param {object} payload.max_rooms количество комнат
 * @return {object} Promise
 */
export default (payload) => {
  let objectData = new FormData();
  objectData.append("guests", payload.guests || "");
  if (typeof payload.time_begin === "number") {
    objectData.append("time_begin", payload.time_begin);
  }
  if (typeof payload.time_end === "number") {
    objectData.append("time_end", payload.time_end);
  }
  objectData.append("date_begin", payload.date_begin || "");
  objectData.append("date_end", payload.date_end || "");
  if (payload.no_time) {
    objectData.append("no_time", payload.no_time || "");
  }
  if (payload.rooms_cnt) {
    objectData.append("rooms_cnt", payload.rooms_cnt);
  }
  if (payload.is_pets) {
    objectData.append("is_pets", payload.is_pets);
  }
  if (payload.documents) {
    objectData.append("documents", payload.documents);
  }
  if (payload.target) {
    objectData.append("target", payload.target);
  }
  if (typeof payload.objects === "number") {
    objectData.append("objects[]", payload.objects);
  } else {
    for (let i = 0; i < payload.objects.length; i++) {
      const element = payload.objects[i];
      if(payload.max_rooms) {
        const room = payload.max_rooms[element] || 1;
        objectData.append(`rooms_cnt[${element}]`, room);
      }
      objectData.append("objects[]", element);
    }
  }
  const ifExists = !payload.objects.length && objectData.get(`rooms_cnt[${payload.objects}]`)
  if(!payload.max_rooms && !ifExists && payload.objects && !payload.rooms_cnt) {
    objectData.append(`rooms_cnt[${payload.objects}]`, String(1));
  }
  if(payload && payload.rooms_cnt) {
    objectData.append(`rooms_cnt[${payload.objects}]`, String(payload.rooms_cnt));
  }
  // const [ myKey, room ] = payload.max_rooms ? Object.entries(payload.max_rooms).shift() : [ false, false ]
  // const ifExists = objectData.get(`rooms_cnt[${myKey}]`)
  // if(myKey && !ifExists){
  //   objectData.append(`rooms_cnt[${myKey}]`, String(room));
  // }
  // objectData.delete('rooms_cnt')
  objectData.append("currency_id", payload.currency_id || "");

  if (payload.ages && payload.ages.length > 0) {
    for (const age of payload.ages) {
      objectData.append("ages[]", age);
    }
  }
  
  return HTTP.post("json/objects/getPricesAndAvailabilities", objectData, {
    adapter: null,
    cancelToken: payload?.cancelToken?.token || null, 
    headers: {
      "api-version": "1.10" // Если указать версию, то будет минимум данных
    },
    cache: false,
  });
};
