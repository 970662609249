import HTTP from "../http-common";

/**
 * Получение данных об объекте
 *
 * @module getObject
 *
 * @param {number} hotelId - ID отеля
 *
 * @return {object} Promise
 *
 */
export default (hotelId) =>
  HTTP.get("json/hotels/getHotel", {
    params: { hotelId },
    headers: {
      "api-version": 1.8, // Если указать версию, то будет минимум данных
    },
    cache: false,
  });
