import HTTP from "../http-common";
/**
 * Получение списка локаций
 *
 * @module getTermSuggestions
 * @return {object} Promise
 */
let cancelSource = null;
export default (query, cancelSource) => {
  // if (cancelSource) {
  //   cancelSource.cancel("Operation canceled by the user.");
  // }
  // cancelSource = CancelToken.source();
  return HTTP.get("json/search/searchObjectsPr", {
    cache: false,
    adapter: null,
    params: {...query},
    cancelToken: cancelSource.token,
    headers: {
      "api-version": String(null), // Если указать версию, то будет минимум данных
    },
    // cancelToken: cancelSource.token
  });
};
