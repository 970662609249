import axios from "axios";
const checkPartners = {
  data() {
    return {
      marker: null, //marker is drawn from url
      cookieDomain: null, //cookie domain
      utmSource: null,
      utmMedium: null,
      utmCampaign: null,
      subId: null,
    };
  },

  methods: {
    setCookiePartner(response) {
      /**
       * set counter in cookie
       *  */
      let sutochno_tracker = {
        marker: this.marker,
        uuid: response,
        time: +new Date(),
      };
      let expireAfter = new Date();
      //setting up  cookie expire date after 30 days
      expireAfter.setDate(expireAfter.getDate() + 30);
      //now setup cookie
      document.cookie =
        "sutochno_tracker=" +
        JSON.stringify(sutochno_tracker) +
        "; domain=" +
        this.cookieDomain +
        "; expires=" +
        expireAfter +
        "; path=/";
    },
    parseDomain() {
      /**
       * domain parsing
       */
      let url = window.location.href;
      let match = url.match(/:\/\/?(.[^/:]+)/i);
      if (
        match != null &&
        match.length >= 2 &&
        typeof match[1] === "string" &&
        match[1].length > 0
      ) {
        let domain = match[1];
        domain = domain.split(".");
        if (domain.length == 1) {
          return match[1];
        } else {
          return (
            "." + domain[domain.length - 2] + "." + domain[domain.length - 1]
          );
        }
      } else {
        return null;
      }
    },
    handleTravelPayouts() {
      const url = "https://sutochno.ru";
      axios
        .get(url, {
          params: {
            utm_source: this.utmSource,
            utm_medium: this.utmMedium,
            utm_campaign: this.utmCampaign,
            sub_id: this.subId,
          },
        })
        .catch((err) => {
          console.warn(err);
        });
    },
    handlePartnerSutochno() {
      const partnerUrl = "https://partner-sutochno.ru/click/link?marker=";
      let cookie = this.$cookies.get("sutochno_tracker");
      //if cookie isn't exist or has a different marker
      if (cookie === null || cookie.marker != this.marker) {
        //check format is numeric (direct link)
        if (Number.isInteger(Number(this.marker))) {
          //then send new event
          axios
            .get(`${partnerUrl + this.marker}`)
            //.then(res => res.text()) // parse response as JSON (can be res.text() for plain response)
            .then((response) => {
              if (response) {
                this.setCookiePartner(response.data);
              }
            })
            .catch((err) => {
              console.warn(err);
            });
        } else {
          //check format is uuid (redirect from partner-sutochno). we already have uuid
          //let re = new RegExp("^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$i");
          if (
            /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(
              this.marker
            )
          ) {
            this.setCookiePartner(this.marker);
          }
        }
      }
    },
    handlePartner() {
      this.cookieDomain = this.parseDomain(); //.sutochno.ru
      //check if query string contains some affiliate param
      const urlParams = new URLSearchParams(window.location.search);
      this.marker = urlParams.get("marker");
      this.utmSource = urlParams.get("utm_source");
      this.utmMedium = urlParams.get("utm_medium");
      this.utmCampaign = urlParams.get("utm_campaign");
      this.subId = urlParams.get("sub_id");
      //partner-sutochno
      if (this.marker) {
        this.handlePartnerSutochno();
      }
      //travelpayouts
      if (
        !this.marker &&
        this.utmSource == "travelpayouts" &&
        this.utmMedium &&
        this.utmCampaign
      ) {
        this.handleTravelPayouts();
      }
    },
  },
  mounted() {
    this.handlePartner(); ///check for affiliate marker in url
  },
};

export { checkPartners };
