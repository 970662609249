/**
 *
 * Отправка данных в Яндекс метрику, при отправке формы
 *
 * @function
 *
 * Все параметры по default
 *
 */
export default function () {
  if (window.ym) {
    return window.ym(8562148, "reachGoal", "bookingstep1com");
  }
}
