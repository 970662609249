import getUserData from "./getUserData";
import auth from "./auth";
import code from "./code";
import suggestCompany from "./suggestCompany";
import addUserRequisite from "./addUserRequisite";
import getUserRequisites from "./getUserRequisites";
import getMyMobiles from "./getMyMobiles";
import addContact from "./addContact";
import approveContact from "./approveContact";
import requestApproveContact from "./requestApproveContact";
import getUsersSearchParams from "./getUsersSearchParams";
import setProperties from "./setProperties";

/**
 * Работа с пользовательскими данными
 *
 * @module Users
 */
export default {
  getUserData,
  auth,
  code,
  suggestCompany,
  addUserRequisite,
  getUserRequisites,
  getMyMobiles,
  addContact,
  approveContact,
  requestApproveContact,
  getUsersSearchParams,
  setProperties
};
