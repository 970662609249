import { cacheApi } from "@sutochno/api";
/**
 * Получить параметры фильтра по ID и типу местоположения
 *
 * @module getFilter
 * @return {object} Promise
 */
export default (params = { locationId: null, locationType: null }) =>
  cacheApi.get({
    url: "/json/search/getFilter",
    config: {
      params,
      headers: {
        "api-version": 1.9, // Если указать версию, то будет минимум данных
      },
    },
    expire: 86400000,
  });
