<i18n>
{
  "ru":{
    "forExample": "Например",
    "petersburg": "Санкт-Петербург",
    "crimea": "Крым",
    "kazan": "Казань",
    "kislovodsk": "Кисловодск",
    "moscow": "Москва",
    "sochi": "Сочи",
    "sheregesh": "Шерегеш",
    "abkhazia": "Абхазия",
    "minsk":"Минск",
    "dagestan": "Дагестан",
    "arkhyz": "Архыз",
    "baikal": "Байкал",
    "karelia": "Карелия"
  },
  "en":{
    "forExample": "For example",
    "petersburg": "St. Petersburg",
    "crimea": "Crimea",
    "kazan": "Kazan",
    "kislovodsk": "Kislovodsk",
    "moscow": "Moscow",
    "sochi": "Sochi",
    "sheregesh": "Sheregesh",
    "abkhazia": "Abkhazia",
    "minsk":"Minsk",
    "dagestan": "Dagestan",
    "arkhyz": "Arkhyz",
    "baikal": "Baikal",
    "karelia": "Karelia"
  }
}
</i18n>

<template>
  <ul class="locations">
    <li>
      <span class="title">{{ t("forExample") }}</span>
    </li>
    <li v-for="(location, index) in preselectedCities" :key="index">
      <button class="location" @click="choiseLocation(location)">
        {{ location.title }}
      </button>
    </li>
  </ul>
</template>

<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

const store = useStore();
const { t } = useI18n();

const emits = defineEmits(["locationSelected"]);

const preselectedCities = computed(() => [
  {
    id: 397367,
    title: t("petersburg"),
    type: "city",
    bbox: {
      SW: {
        lat: 59.744311,
        lng: 30.043373,
      },
      NE: {
        lat: 60.090935,
        lng: 30.566426,
      },
    },
  },
  {
    id: 397366,
    title: t("moscow"),
    type: "city",
    bbox: {
      SW: {
        lat: 55.49133,
        lng: 37.326051,
      },
      NE: {
        lat: 55.957565,
        lng: 37.96779,
      },
    },
  },
  {
    id: 287390,
    title: t("sochi"),
    type: "city",
    bbox: {
      SW: {
        lat: 43.38669197168201,
        lng: 39.188110968098044,
      },
      NE: {
        lat: 44.00668198708445,
        lng: 40.290568778291345,
      },
    },
  },
  /* {
  id: 411389,
  title: t("baikal"),
  type: "city",
  bbox: {
    SW: { lat: 51.1869, lng: 103.3837 },
    NE: { lat: 56.0172, lng: 110.3931 },
  },
}, */
  {
    id: 398612,
    title: t("minsk"),
    type: "city",
    bbox: {
      SW: {
        lat: 53.80038928698426,
        lng: 27.368931835937094,
      },
      NE: {
        lat: 53.98457211750567,
        lng: 27.75099115624918,
      },
    },
  },
  {
    id: 281471,
    title: t("kazan"),
    type: "city",
    bbox: {
      SW: {
        lat: 55.747455,
        lng: 49.048392,
      },
      NE: {
        lat: 55.839633,
        lng: 49.238044,
      },
    },
  },
  {
    id: 19815,
    title: t("dagestan"),
    type: "region",
    bbox: {
      SW: {
        lat: 41.185996,
        lng: 45.104365,
      },
      NE: {
        lat: 44.992524,
        lng: 48.796486,
      },
    },
  },
  {
    id: 289700,
    title: t("kislovodsk"), // Кисловодск
    type: "city",
    bbox: {
      SW: {
        lat: 43.843869,
        lng: 42.659879,
      },
      NE: {
        lat: 43.960004,
        lng: 42.828601,
      },
    },
  },
  {
    id: 242,
    title: t("abkhazia"),
    type: "country",
    bbox: {
      SW: {
        lat: 42.326447,
        lng: 39.828569,
      },
      NE: {
        lat: 43.586419,
        lng: 42.132631,
      },
    },
  },
]);

function choiseLocation(location) {
  store.dispatch("search/setLocation", location);
  store.commit("search/setBound", {
    NE: {
      lat: location.bbox.NE.lat,
      lng: location.bbox.NE.lng,
    },
    SW: {
      lat: location.bbox.SW.lat,
      lng: location.bbox.SW.lng,
    },
  });

  emits("locationSelected", location);
}
</script>

<style lang="scss" scoped>
.locations {
  padding: 0;
  margin: 0;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  gap: 8px;
  list-style: none;
  overflow-x: auto;

  .title {
    color: var(--color-gray-g5);
  }

  .location {
    display: inline-block;
    padding: 3px 11px;
    cursor: pointer;
    color: var(--color-main-black);
    background: var(--color-gray-g3);
    backdrop-filter: blur(15px);
    border-radius: 11px;
    border: none;
    white-space: nowrap;
  }
}
.mobile .locations .title {
  display: none;
}
</style>
