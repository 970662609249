<template>
  <div class="main__notfound">
    <Header />

    <BaseNotFound />

    <Footer />
  </div>
</template>

<script>
import { Header } from "@sutochno/header";
import { Footer } from "@sutochno/footer";
import BaseNotFound from "@/components/base/BaseNotFound.vue";

export default {
  components: {
    Header,
    Footer,
    BaseNotFound,
  },
};
</script>

<style lang="scss" scoped>
.main__notfound {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: #f1f3fb;
}
</style>
