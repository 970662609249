import HTTP from "../http-common";

/**
 * Получение цены
 *
 * @module getPrices
 * @param {object} payload Передаваемый объект
 * @param {string} payload.rooms_cnt Сколько комнат
 * @param {string} payload.guests Сколько гостей
 * @param {string} payload.time_begin Время заезда
 * @param {string} payload.time_end Время отъезда
 * @param {string} payload.date_begin Дата заезда в формате Y-m-d hh:mm:ss
 * @param {string} payload.date_end Дата отъезда в формате Y-m-d hh:mm:ss
 * @param {array} payload.objectId id объекта
 * @param {object} payload.max_rooms количество комнат
 * @return {object} Promise
 */
export default (payload) => {
  let objectData = new FormData();
  if (payload.rooms_cnt) {
    objectData.append("rooms_cnt", payload.rooms_cnt || "");
  }
  objectData.append("guests", payload.guests || "");
  objectData.append("time_begin", payload.time_begin || "");
  objectData.append("time_end", payload.time_end || "");
  objectData.append("date_begin", payload.date_begin || "");
  objectData.append("date_end", payload.date_end || "");
  objectData.append("object_id", payload.object_id || "");
  objectData.append("currency_id", payload.currency_id || "");

  return HTTP.post("json/objects/getPrices", objectData, {
    headers: {
      // "api-version":  // Если указать версию, то будет минимум данных
    },
    cache: false,
  });
};
