import HTTP from "../http-common";
/**
 * Получение общего числа объектов в городе
 *
 * @module getTotalFound
 * @return {object} Promise
 */
export default (location) => {
  return HTTP.get("rest/search/getTotalFound", {
    headers: {
      "api-version": String(null), // Если указать версию, то будет минимум данных
    },
    params: {
      location_id: location.id,
      location_type: location.type
    },
    cache: false,
  });
};