<template>
  <SearchPanel @getObjects="doSearch" />
</template>

<script>
import SearchPanel from "@/components/mobile/NewSearch/SearchParams/SearchPanel.vue";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";

export default {
  components: {
    SearchPanel
  },
  computed: {
    ...mapState("search", [
      "term",
      "guests",
      "checkIn",
      "checkOut",
      "analiticParamForSearchWidList"
    ]),
    ...mapGetters("search", { getterOccupied: "occupied" }),
  },
  methods: {
    async doSearch() {
      if (!this.checkIn && !this.checkOut) {
        return false;
      }
      const query = _.pickBy({
        occupied: this.getterOccupied,
        guests_adults: this.guests.adults,
        guests_childrens: this.guests.childrens.join(","),
        term: this.term,
        pets: this.guests?.pets?.value || false,
      });

      if (this.analiticParamForSearchWidList?.type === "history") {
        query.search_history = 1;
      } else if (this.analiticParamForSearchWidList?.type === "hotels") {
        query.search_hotel = this.analiticParamForSearchWidList.value;
      }

      if(this.$route.name === undefined) {
        query.from = 404;
      }

      await this.$nextTick();
      this.$router
        .push({
          name: "Search",
          query,
        })
        .catch((err) => {})
        .finally(() => {
          this.$store.commit("search/setLoadingLocation", false);
          this.$store.commit("search/setShowSearchModal", false);
        });
    },
  }
}
</script>