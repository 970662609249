import VueGtag from "vue-gtag";
const isProd = process.env.NODE_ENV === "production";
export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.use(
    VueGtag,
    {
      enabled: isProd,
      config: {
        id: "G-B0K4L0V6J8",
      }
    }
  )
})