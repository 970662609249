import { default as BookingWLobrcWvbKfUMeta } from "/app/pages/Booking/BookingWL.vue?macro=true";
import { default as indexhv61tE1ElUMeta } from "/app/pages/Booking/index.vue?macro=true";
import { default as LightBookingvTsLOn1OvUMeta } from "/app/pages/Booking/LightBooking.vue?macro=true";
import { default as DetailFotokDJHa0Srf0Meta } from "/app/pages/Detail/DetailFoto.vue?macro=true";
import { default as indexgk4Cv0aJeGMeta } from "/app/pages/Detail/index.vue?macro=true";
import { default as Reviewqx7dcps92DMeta } from "/app/pages/Detail/Review.vue?macro=true";
import { default as ReviewComment3P1FH7vdnPMeta } from "/app/pages/Detail/ReviewComment.vue?macro=true";
import { default as ReviewLocationAhs47Vew4oMeta } from "/app/pages/Detail/ReviewLocation.vue?macro=true";
import { default as HotelN1ViuEuZRaMeta } from "/app/pages/Hotel.vue?macro=true";
import { default as MainmchMlbXHYOMeta } from "/app/pages/Main.vue?macro=true";
import { default as NoticesFramensskhMNFP6Meta } from "/app/pages/NoticesFrame.vue?macro=true";
import { default as PaymentsPjhyFG86liMeta } from "/app/pages/Payments.vue?macro=true";
import { default as PaymentsFrameI1mM0xgOj6Meta } from "/app/pages/PaymentsFrame.vue?macro=true";
import { default as SearchgilRwFjeRXMeta } from "/app/pages/Search.vue?macro=true";
export default [
  {
    name: BookingWLobrcWvbKfUMeta?.name ?? "Booking-BookingWL",
    path: BookingWLobrcWvbKfUMeta?.path ?? "/Booking/BookingWL",
    meta: BookingWLobrcWvbKfUMeta || {},
    alias: BookingWLobrcWvbKfUMeta?.alias || [],
    redirect: BookingWLobrcWvbKfUMeta?.redirect,
    component: () => import("/app/pages/Booking/BookingWL.vue").then(m => m.default || m)
  },
  {
    name: indexhv61tE1ElUMeta?.name ?? "Booking",
    path: indexhv61tE1ElUMeta?.path ?? "/Booking",
    meta: indexhv61tE1ElUMeta || {},
    alias: indexhv61tE1ElUMeta?.alias || [],
    redirect: indexhv61tE1ElUMeta?.redirect,
    component: () => import("/app/pages/Booking/index.vue").then(m => m.default || m)
  },
  {
    name: LightBookingvTsLOn1OvUMeta?.name ?? "Booking-LightBooking",
    path: LightBookingvTsLOn1OvUMeta?.path ?? "/Booking/LightBooking",
    meta: LightBookingvTsLOn1OvUMeta || {},
    alias: LightBookingvTsLOn1OvUMeta?.alias || [],
    redirect: LightBookingvTsLOn1OvUMeta?.redirect,
    component: () => import("/app/pages/Booking/LightBooking.vue").then(m => m.default || m)
  },
  {
    name: DetailFotokDJHa0Srf0Meta?.name ?? "Detail-DetailFoto",
    path: DetailFotokDJHa0Srf0Meta?.path ?? "/Detail/DetailFoto",
    meta: DetailFotokDJHa0Srf0Meta || {},
    alias: DetailFotokDJHa0Srf0Meta?.alias || [],
    redirect: DetailFotokDJHa0Srf0Meta?.redirect,
    component: () => import("/app/pages/Detail/DetailFoto.vue").then(m => m.default || m)
  },
  {
    name: indexgk4Cv0aJeGMeta?.name ?? "Detail",
    path: indexgk4Cv0aJeGMeta?.path ?? "/Detail",
    meta: indexgk4Cv0aJeGMeta || {},
    alias: indexgk4Cv0aJeGMeta?.alias || [],
    redirect: indexgk4Cv0aJeGMeta?.redirect,
    component: () => import("/app/pages/Detail/index.vue").then(m => m.default || m)
  },
  {
    name: Reviewqx7dcps92DMeta?.name ?? "Detail-Review",
    path: Reviewqx7dcps92DMeta?.path ?? "/Detail/Review",
    meta: Reviewqx7dcps92DMeta || {},
    alias: Reviewqx7dcps92DMeta?.alias || [],
    redirect: Reviewqx7dcps92DMeta?.redirect,
    component: () => import("/app/pages/Detail/Review.vue").then(m => m.default || m)
  },
  {
    name: ReviewComment3P1FH7vdnPMeta?.name ?? "Detail-ReviewComment",
    path: ReviewComment3P1FH7vdnPMeta?.path ?? "/Detail/ReviewComment",
    meta: ReviewComment3P1FH7vdnPMeta || {},
    alias: ReviewComment3P1FH7vdnPMeta?.alias || [],
    redirect: ReviewComment3P1FH7vdnPMeta?.redirect,
    component: () => import("/app/pages/Detail/ReviewComment.vue").then(m => m.default || m)
  },
  {
    name: ReviewLocationAhs47Vew4oMeta?.name ?? "Detail-ReviewLocation",
    path: ReviewLocationAhs47Vew4oMeta?.path ?? "/Detail/ReviewLocation",
    meta: ReviewLocationAhs47Vew4oMeta || {},
    alias: ReviewLocationAhs47Vew4oMeta?.alias || [],
    redirect: ReviewLocationAhs47Vew4oMeta?.redirect,
    component: () => import("/app/pages/Detail/ReviewLocation.vue").then(m => m.default || m)
  },
  {
    name: HotelN1ViuEuZRaMeta?.name ?? "Hotel",
    path: HotelN1ViuEuZRaMeta?.path ?? "/Hotel",
    meta: HotelN1ViuEuZRaMeta || {},
    alias: HotelN1ViuEuZRaMeta?.alias || [],
    redirect: HotelN1ViuEuZRaMeta?.redirect,
    component: () => import("/app/pages/Hotel.vue").then(m => m.default || m)
  },
  {
    name: MainmchMlbXHYOMeta?.name ?? "Main",
    path: MainmchMlbXHYOMeta?.path ?? "/Main",
    meta: MainmchMlbXHYOMeta || {},
    alias: MainmchMlbXHYOMeta?.alias || [],
    redirect: MainmchMlbXHYOMeta?.redirect,
    component: () => import("/app/pages/Main.vue").then(m => m.default || m)
  },
  {
    name: NoticesFramensskhMNFP6Meta?.name ?? "NoticesFrame",
    path: NoticesFramensskhMNFP6Meta?.path ?? "/NoticesFrame",
    meta: NoticesFramensskhMNFP6Meta || {},
    alias: NoticesFramensskhMNFP6Meta?.alias || [],
    redirect: NoticesFramensskhMNFP6Meta?.redirect,
    component: () => import("/app/pages/NoticesFrame.vue").then(m => m.default || m)
  },
  {
    name: PaymentsPjhyFG86liMeta?.name ?? "Payments",
    path: PaymentsPjhyFG86liMeta?.path ?? "/Payments",
    meta: PaymentsPjhyFG86liMeta || {},
    alias: PaymentsPjhyFG86liMeta?.alias || [],
    redirect: PaymentsPjhyFG86liMeta?.redirect,
    component: () => import("/app/pages/Payments.vue").then(m => m.default || m)
  },
  {
    name: PaymentsFrameI1mM0xgOj6Meta?.name ?? "PaymentsFrame",
    path: PaymentsFrameI1mM0xgOj6Meta?.path ?? "/PaymentsFrame",
    meta: PaymentsFrameI1mM0xgOj6Meta || {},
    alias: PaymentsFrameI1mM0xgOj6Meta?.alias || [],
    redirect: PaymentsFrameI1mM0xgOj6Meta?.redirect,
    component: () => import("/app/pages/PaymentsFrame.vue").then(m => m.default || m)
  },
  {
    name: SearchgilRwFjeRXMeta?.name ?? "Search",
    path: SearchgilRwFjeRXMeta?.path ?? "/Search",
    meta: SearchgilRwFjeRXMeta || {},
    alias: SearchgilRwFjeRXMeta?.alias || [],
    redirect: SearchgilRwFjeRXMeta?.redirect,
    component: () => import("/app/pages/Search.vue").then(m => m.default || m)
  }
]